import React from 'react';
import { useIntl } from 'react-intl';
import { SVGIcon } from './svgHelpers';

export const SplitPayIntroPageIcon: SVGIcon = (props) => {
  const { className, ...otherProps } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'SplitPay Payment Plan Icon',
    description: 'SplitPay Payment Plan Icon',
  });

  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="SplitPay-payment-plan-icon"
      {...otherProps}
    >
      <title id="SplitPay-payment-plan-icon">{title}</title>
      <path
        d="M39.9998 21.2773C39.9998 23.7359 39.5156 26.1706 38.5747 28.4421C37.6338 30.7136 36.2547 32.7776 34.5161 34.5161C32.7776 36.2547 30.7136 37.6338 28.4421 38.5747C26.1706 39.5156 23.7359 39.9998 21.2773 39.9998V32.7309C22.7814 32.7309 24.2707 32.4346 25.6604 31.859C27.05 31.2834 28.3126 30.4397 29.3762 29.3762C30.4397 28.3126 31.2834 27.05 31.859 25.6604C32.4346 24.2707 32.7309 22.7814 32.7309 21.2773H39.9998Z"
        fill="#13294B"
      />
      <path
        d="M0.000164032 21.2773C0.000164032 23.7359 0.484436 26.1706 1.42533 28.4421C2.36623 30.7136 3.74533 32.7776 5.48388 34.5161C7.22243 36.2547 9.28639 37.6338 11.5579 38.5747C13.8294 39.5156 16.2641 39.9998 18.7227 39.9998V32.7309C17.2186 32.7309 15.7293 32.4346 14.3396 31.859C12.95 31.2834 11.6874 30.4397 10.6238 29.3762C9.56026 28.3126 8.7166 27.05 8.141 25.6604C7.5654 24.2707 7.26915 22.7814 7.26915 21.2773H0.000164032Z"
        fill="#13294B"
      />
      <path
        d="M39.9998 18.7227C39.9998 16.2641 39.5156 13.8294 38.5747 11.5579C37.6338 9.28639 36.2547 7.22243 34.5161 5.48388C32.7776 3.74533 30.7136 2.36623 28.4421 1.42533C26.1706 0.484436 23.7359 0.000164032 21.2773 0.000164032V7.26915C22.7814 7.26915 24.2707 7.5654 25.6604 8.141C27.05 8.7166 28.3126 9.56026 29.3762 10.6238C30.4397 11.6874 31.2834 12.95 31.859 14.3396C32.4346 15.7293 32.7309 17.2186 32.7309 18.7227H39.9998Z"
        fill="#13294B"
      />
      <path
        d="M0.000164032 18.7227C0.000164032 16.2641 0.484436 13.8294 1.42533 11.5579C2.36623 9.28639 3.74533 7.22243 5.48388 5.48388C7.22243 3.74533 9.28639 2.36623 11.5579 1.42533C13.8294 0.484436 16.2641 0.000164032 18.7227 0.000164032V7.26915C17.2186 7.26915 15.7293 7.5654 14.3396 8.141C12.95 8.7166 11.6874 9.56026 10.6238 10.6238C9.56026 11.6874 8.7166 12.95 8.141 14.3396C7.5654 15.7293 7.26915 17.2186 7.26915 18.7227H0.000164032Z"
        fill="#13294B"
      />
    </svg>
  );
};
