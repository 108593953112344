import React from 'react';
import { useIntl } from 'react-intl';
import { SVGIcon } from './svgHelpers';

export const InstallmentsIntroPageIcon: SVGIcon = (props) => {
  const { className, ...otherProps } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'Installments Intro Page Icon',
    description: 'Installments Intro Page Icon',
  });

  return (
    <svg
      className={className}
      width="40"
      height="42"
      viewBox="0 0 40 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="installments-intro-page-icon"
      {...otherProps}
    >
      <title id="installments-payment-plan-icon">{title}</title>
      <rect
        x="1.25"
        y="1.25"
        width="35.4998"
        height="35.1458"
        rx="4.75"
        fill="#F4F9FA"
        stroke="#13294B"
        strokeWidth="2.5"
      />
      <path d="M37.9998 8.23828H0" stroke="#13294B" strokeWidth="2.5" />
      <ellipse
        cx="9.49999"
        cy="17.6458"
        rx="2.37499"
        ry="2.35286"
        fill="#13294B"
      />
      <ellipse
        cx="9.49999"
        cy="25.8841"
        rx="2.37499"
        ry="2.35286"
        fill="#13294B"
      />
      <ellipse
        cx="17.8047"
        cy="17.6458"
        rx="2.37499"
        ry="2.35286"
        fill="#13294B"
      />
      <path
        d="M28.4921 41.0026C34.2823 41.0026 38.9921 36.35 38.9921 30.5911C38.9921 24.8322 34.2823 20.1797 28.4921 20.1797C22.702 20.1797 17.9922 24.8322 17.9922 30.5911C17.9922 36.35 22.702 41.0026 28.4921 41.0026Z"
        fill="#13294B"
        stroke="#EEEEEE"
        strokeWidth="2"
      />
      <path
        d="M28.6563 34.6245C27.9843 34.6245 27.3816 34.5125 26.8483 34.2885C26.315 34.0538 25.8936 33.7338 25.5843 33.3285C25.275 32.9125 25.1043 32.4378 25.0723 31.9045H26.5763C26.619 32.3418 26.8216 32.6991 27.1843 32.9765C27.5576 33.2538 28.043 33.3925 28.6403 33.3925C29.195 33.3925 29.6323 33.2698 29.9523 33.0245C30.2723 32.7791 30.4323 32.4698 30.4323 32.0965C30.4323 31.7125 30.2616 31.4298 29.9203 31.2485C29.579 31.0565 29.051 30.8698 28.3363 30.6885C27.6856 30.5178 27.1523 30.3471 26.7363 30.1765C26.331 29.9951 25.979 29.7338 25.6803 29.3925C25.3923 29.0405 25.2483 28.5818 25.2483 28.0165C25.2483 27.5685 25.3816 27.1578 25.6483 26.7845C25.915 26.4111 26.2936 26.1178 26.7843 25.9045C27.275 25.6805 27.835 25.5685 28.4643 25.5685C29.435 25.5685 30.219 25.8138 30.8163 26.3045C31.4136 26.7951 31.7336 27.4671 31.7763 28.3205H30.3203C30.2883 27.8618 30.1016 27.4938 29.7603 27.2165C29.4296 26.9391 28.9816 26.8005 28.4163 26.8005C27.8936 26.8005 27.4776 26.9125 27.1683 27.1365C26.859 27.3605 26.7043 27.6538 26.7043 28.0165C26.7043 28.3045 26.795 28.5445 26.9763 28.7365C27.1683 28.9178 27.403 29.0671 27.6803 29.1845C27.9683 29.2911 28.363 29.4138 28.8643 29.5525C29.4936 29.7231 30.0056 29.8938 30.4003 30.0645C30.795 30.2245 31.131 30.4698 31.4083 30.8005C31.6963 31.1311 31.8456 31.5631 31.8563 32.0965C31.8563 32.5765 31.723 33.0085 31.4563 33.3925C31.1896 33.7765 30.811 34.0805 30.3203 34.3045C29.8403 34.5178 29.2856 34.6245 28.6563 34.6245Z"
        fill="#EEEEEE"
      />
      <path d="M28.4922 23.5391V26.186" stroke="#EEEEEE" strokeWidth="1.5" />
      <path d="M28.4922 34.7109V37.3579" stroke="#EEEEEE" strokeWidth="1.5" />
    </svg>
  );
};
