import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, FormattedList } from 'react-intl';
import {
  frequencyString,
  toMoneyValue,
  ComputedPaymentTypeValues,
} from '@ads-bread/shared/bread/util';
import { ButtonClickedEvents } from '../../lib/analytics';
import { useFormattedNumber } from '../../lib/hooks/useFormattedNumber';
import { BreadLogo } from '../../components/svgs/BreadLogo';
import IntroScreen, {
  IntroBaseSubHeading,
  formattingValues,
  IntroTitle,
  InstallmentsCardProps,
} from '../../components/pages/intro/Intro';
import { IntroCard } from '../../components/IntroCard';
import { Installments } from '../../components/svgs/Installments';
import { SplitPay } from '../../components/svgs/SplitPay';
import { SVGIcon } from '../../components/svgs/svgHelpers';
import { RouteMachineServiceScreenProps } from '../../components/RouteMachineService';
import { EligibilityCheckHelp } from '../../components/EligibilityCheckHelp';
import { MarketingPromo } from '../../components/MarketingPromo';
import { Button } from '../../components/Button';
import { useMerchantConfig } from '../../components/MerchantContext';
import {
  useFeatureFlags,
  IntroPageABPhaseOneResult,
} from '../../components/FeatureFlagsContext';
import { IntroSideBySideScreen } from '../../components/intro-ab-test/intro-side-by-side';
import { IntroStreamlinedScreen } from '../../components/intro-ab-test/intro-streamlined';
import { Disclaimers } from '../../components/pages/intro/Disclaimers';

const InstallmentsCard: FunctionComponent<InstallmentsCardProps> = ({
  isDefaultOrder,
  paymentTypeValues,
  installmentIcon,
}) => {
  const { disclosureSymbolInstallments } = useMerchantConfig();
  const { aprFormatter, formatCurrency } = useFormattedNumber();

  return (
    <IntroCard
      icon={installmentIcon}
      className="mt-11"
      dataTestId="installments-card"
    >
      <h3 className="flex items-center justify-center text-lg font-extrabold leading-normal mt-5 mb-2 uppercase">
        <BreadLogo
          className="w-16 h-6 pl-1"
          fill="#0F2233"
          fillLogo="#0F2233"
        />
        <FormattedMessage
          defaultMessage="Installments"
          description="Intro screen installments card heading"
        />
      </h3>
      <div
        id="footnoteDisclosureSymbolInstallments-1-rf"
        className="text-base font-bold leading-5"
      >
        {isDefaultOrder ? (
          <FormattedMessage
            defaultMessage="Flexible monthly payment plans"
            description="Intro screen installments card sub heading with default order"
            values={formattingValues}
          />
        ) : (
          <a href="#footnoteDisclosureSymbolInstallments">
            <FormattedMessage
              defaultMessage="Monthly payments as low as {promoOfferAmount}/{promoOfferFrequency}<sup>{disclosureSymbolInstallments}</sup>"
              description="Intro screen installments card sub heading with order amount"
              values={{
                ...formattingValues,
                promoOfferAmount: formatCurrency(
                  paymentTypeValues.promoOfferAmount
                ),
                promoOfferFrequency: frequencyString(
                  paymentTypeValues.promoOfferFrequency
                ),
                disclosureSymbolInstallments,
              }}
            />
          </a>
        )}
      </div>
      <ul
        id="footnoteDisclosureSymbolInstallments-2-rf"
        className="list-none list-inside flex flex-col items-center text-sm leading-normal"
      >
        <li className="mt-4 text-base font-normal">
          <a href="#footnoteDisclosureSymbolInstallments">
            <FormattedMessage
              defaultMessage="{lengthValues} {lengthSize,
                      plural,
                      =0 {No}
                      one {month}
                      other {month}
                    }
                    payment {lengthSize,
                      plural,
                      =0 {plans}
                      one {plan}
                      other {plans}
                    }
                    available<sup>{disclosureSymbolInstallments}</sup>"
              description="Intro screen installments card bullet one"
              values={{
                ...formattingValues,
                lengthValues: (
                  <FormattedList
                    value={Array.from(new Set(paymentTypeValues.lengthOptions))}
                    type={'conjunction'}
                  />
                ),
                lengthSize: paymentTypeValues.lengthOptions.length,
                disclosureSymbolInstallments,
              }}
            />
          </a>
        </li>
        <li
          id="footnoteDisclosureSymbolInstallments-3-rf"
          className="mt-4 text-base font-normal"
        >
          <a href="#footnoteDisclosureSymbolInstallments">
            <FormattedMessage
              defaultMessage="Starting at {minInterestRateBPS} APR<sup>{disclosureSymbolInstallments}</sup>"
              description="Intro screen installments card bullet two"
              values={{
                minInterestRateBPS: aprFormatter(
                  paymentTypeValues.minInterestRateBPS
                ),
                disclosureSymbolInstallments,
              }}
            />
          </a>
        </li>
      </ul>
    </IntroCard>
  );
};

const SplitPayCard: FunctionComponent<{
  isDefaultOrder: boolean;
  paymentTypeValues: ComputedPaymentTypeValues;
  splitPayIcon: SVGIcon;
}> = ({ isDefaultOrder, paymentTypeValues, splitPayIcon }) => {
  const { disclosureSymbolSplitpay, disclosureSymbolSplitpayInterest } =
    useMerchantConfig();
  const { formatCurrency } = useFormattedNumber();

  return (
    <IntroCard icon={splitPayIcon} dataTestId="splitpay-card" className="mt-11">
      <h3 className="flex items-center justify-center text-lg font-extrabold leading-normal mt-5 mb-2 uppercase">
        <BreadLogo
          className="w-16 h-6 pl-1"
          fill="#0F2233"
          fillLogo="#0F2233"
        />
        <FormattedMessage
          defaultMessage="SplitPay"
          description="Intro screen splitPay card heading"
        />
      </h3>
      <div
        id="footnoteDisclosureSymbolSplitPay-1-rf"
        className="text-base font-bold"
      >
        {isDefaultOrder ? (
          <a href="#footnoteDisclosureSymbolSplitPay">
            <FormattedMessage
              defaultMessage="4 interest-free payments every 2 weeks<sup>{disclosureSymbolSplitpay}</sup>"
              description="Intro screen splitPay card sub heading with default order"
              values={{ ...formattingValues, disclosureSymbolSplitpay }}
            />
          </a>
        ) : (
          <a href="#footnoteDisclosureSymbolSplitPay">
            <FormattedMessage
              defaultMessage="{promoOfferLength} interest-free payments of {promoOfferAmount}<sup>{disclosureSymbolSplitpay}</sup>"
              description="Intro screen splitPay card sub heading with order amount"
              values={{
                ...formattingValues,
                promoOfferAmount: formatCurrency(
                  paymentTypeValues.promoOfferAmount
                ),
                promoOfferLength: paymentTypeValues.promoOfferLength,
                disclosureSymbolSplitpay,
              }}
            />
          </a>
        )}
      </div>
      <ul
        id="footnoteDisclosureSymbolSplitPay-2-rf"
        className="list-none list-inside flex flex-col items-center text-sm leading-normal"
      >
        <li className="mt-4 text-base font-normal">
          <a href="#footnoteDisclosureSymbolSplitPay">
            <FormattedMessage
              defaultMessage="Payments automatically applied to your debit or credit card every 2 weeks"
              description="Intro screen splitPay card bullet one"
            />
          </a>
        </li>
        <li
          id="footnoteDisclosureSymbolSplitPayInterest-rf"
          className="mt-4 text-base font-normal"
        >
          <a href="#footnoteDisclosureSymbolSplitPayInterest">
            <FormattedMessage
              defaultMessage="Always interest-free (0.00% APR) <sup>{disclosureSymbolSplitpayInterest}</sup>"
              description="Intro screen splitPay card bullet two"
              values={{
                ...formattingValues,
                disclosureSymbolSplitpayInterest,
              }}
            />
          </a>
        </li>
        {isDefaultOrder && (
          <li
            id="footnoteDisclosureSymbolSplitPay-3-rf"
            className="mt-4 text-sm text-dark-blue-grey font-normal"
          >
            <a href="#footnoteDisclosureSymbolSplitPay">
              <FormattedMessage
                defaultMessage="Available for purchases between {minCartSize} and {maxCartSize}"
                description="Intro screen splitPay card bullet three"
                values={{
                  ...formattingValues,
                  minCartSize: formatCurrency(
                    toMoneyValue(paymentTypeValues.minCartSize)
                  ),
                  maxCartSize: formatCurrency(
                    toMoneyValue(paymentTypeValues.maxCartSize || 0)
                  ),
                }}
              />
            </a>
          </li>
        )}
      </ul>
    </IntroCard>
  );
};

export const IntroDefault: FunctionComponent<RouteMachineServiceScreenProps> = (
  props
) => {
  return (
    <IntroScreen {...props}>
      {({
        hasSplitpay,
        hasInstallments,
        sdkInstallmentsPaymentTypeValues,
        isDefaultOrder,
        sdkSplitPayPaymentTypeValues,
        synthesizedSplitPayPaymentValues,
        useSyntheticPaymentTypeValues,
        exampleInstallmentsPaymentTypeValues,
      }) => {
        const splitPayPaymentTypeValues = useSyntheticPaymentTypeValues
          ? synthesizedSplitPayPaymentValues
          : sdkSplitPayPaymentTypeValues;

        const installmentsPaymentTypeValues = useSyntheticPaymentTypeValues
          ? exampleInstallmentsPaymentTypeValues
          : sdkInstallmentsPaymentTypeValues;

        return (
          <>
            <IntroTitle>
              <FormattedMessage
                defaultMessage="Pay Over Time"
                description="Intro screen heading"
              />
            </IntroTitle>

            <IntroBaseSubHeading>
              {hasSplitpay && hasInstallments ? (
                <FormattedMessage
                  defaultMessage="Make flexible payments with our pay-over-time plans"
                  description="ADS Intro screen subheading SxS"
                />
              ) : hasSplitpay ? (
                <FormattedMessage
                  defaultMessage="Split your purchase into 4 interest-free payments"
                  description="ADS Intro screen subheading splitpay only"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="With flexible monthly payment plans"
                  description="ADS Intro screen subheading installmentsonly"
                />
              )}
            </IntroBaseSubHeading>

            {installmentsPaymentTypeValues && (
              <InstallmentsCard
                isDefaultOrder={isDefaultOrder}
                paymentTypeValues={installmentsPaymentTypeValues}
                installmentIcon={Installments}
              />
            )}
            {splitPayPaymentTypeValues && (
              <SplitPayCard
                isDefaultOrder={isDefaultOrder}
                paymentTypeValues={splitPayPaymentTypeValues}
                splitPayIcon={SplitPay}
              />
            )}

            <div className="bg-merchant bg-opacity-20 text-center my-8 px-1 font-normal">
              <FormattedMessage
                defaultMessage="Checking your eligibility is quick, easy, and won’t affect your credit <NoWrapTooltip>score</NoWrapTooltip>"
                description="Intro screen body"
                values={{
                  NoWrapTooltip: (chunks: ReactNode) => (
                    <span className="whitespace-nowrap">
                      {chunks}

                      {EligibilityCheckHelp && (
                        <EligibilityCheckHelp dataTestID="intro-eligibility-check-help" />
                      )}
                    </span>
                  ),
                }}
              />
            </div>

            <Button
              analyticsEventName={ButtonClickedEvents.GetStarted}
              onClick={props.forward}
            >
              <FormattedMessage
                defaultMessage="Get started"
                description="Intro screen button text"
              />
            </Button>

            {MarketingPromo && <MarketingPromo />}
            <Disclaimers
              hasInstallments={hasInstallments}
              hasSplitPay={hasSplitpay}
              installmentsPaymentTypeValues={
                exampleInstallmentsPaymentTypeValues
              }
              splitPayPaymentTypeValues={splitPayPaymentTypeValues}
            />
          </>
        );
      }}
    </IntroScreen>
  );
};

export const Intro: FunctionComponent<RouteMachineServiceScreenProps> = (
  props
) => {
  const { introPageABPhaseOne } = useFeatureFlags();
  if (introPageABPhaseOne === IntroPageABPhaseOneResult.SideBySide) {
    return <IntroSideBySideScreen {...props} />;
  } else if (introPageABPhaseOne === IntroPageABPhaseOneResult.Streamlined) {
    return <IntroStreamlinedScreen {...props} />;
  } else {
    return <IntroDefault {...props} />;
  }
};
export default Intro;
