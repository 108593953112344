import React from 'react';
import { useIntl } from 'react-intl';
import { SVGIcon } from './svgHelpers';

export const SplitPay: SVGIcon = (props) => {
  const { className, ...otherProps } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'SplitPay Payment Plan Icon',
    description: 'SplitPay Payment Plan Icon',
  });
  return (
    <svg
      className={`fill-current ${className}`}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="splitpay-payment-plan-icon"
      {...otherProps}
    >
      <title id="splitpay-payment-plan-icon">{title}</title>
      <path d="M38.2945 17.6507C37.5129 17.6507 36.8062 17.11 36.6295 16.3124C35.2107 9.8726 30.1299 4.78712 23.6838 3.36854C22.7629 3.16512 22.1847 2.25509 22.3882 1.3397C22.5916 0.418963 23.4911 -0.153822 24.4173 0.0442437C32.1536 1.75189 38.2571 7.84912 39.9596 15.5844C40.1631 16.5051 39.5795 17.4098 38.664 17.6132C38.5408 17.64 38.4177 17.6507 38.2945 17.6507Z" />
      <path
        opacity="0.35"
        d="M24.053 40C23.2713 40 22.5646 39.4593 22.3879 38.6617C22.1845 37.741 22.768 36.8363 23.6835 36.6329C30.1296 35.2143 35.2105 30.1288 36.6346 23.689C36.838 22.7682 37.7428 22.1901 38.6637 22.3935C39.5846 22.5969 40.1628 23.507 39.9594 24.4224C38.2515 32.1576 32.1534 38.2602 24.417 39.9625C24.2992 39.9839 24.1761 40 24.053 40Z"
      />
      <path
        opacity="0.35"
        d="M15.9527 40C15.8296 40 15.7064 39.9893 15.5833 39.9625C7.84694 38.2548 1.74886 32.1523 0.0409745 24.417C-0.162473 23.4962 0.4211 22.5916 1.33661 22.3881C2.25748 22.1847 3.16229 22.7629 3.36574 23.6836C4.78452 30.1288 9.87071 35.2089 16.3168 36.6275C17.2377 36.8309 17.8159 37.7409 17.6124 38.6563C17.4411 39.4539 16.7344 40 15.9527 40Z"
      />
      <path
        opacity="0.35"
        d="M1.7058 17.6507C1.58266 17.6507 1.45952 17.64 1.33638 17.6132C0.415511 17.4098 -0.162709 16.4998 0.040739 15.5844C1.74327 7.8491 7.8467 1.74652 15.5831 0.0388706C16.5039 -0.159195 17.4087 0.418943 17.6122 1.33433C17.8156 2.25507 17.2321 3.15975 16.3166 3.36317C9.87047 4.7871 4.78964 9.87258 3.3655 16.3124C3.18882 17.11 2.48746 17.6507 1.7058 17.6507Z"
      />
      <path d="M23.4585 19.4224C23.3193 19.3261 22.0451 18.4696 20.1337 18.3143C19.2075 18.2394 18.5758 17.8219 18.5704 17.8219C18.3509 17.6505 17.9868 17.2812 17.9868 16.7405C17.9868 15.9001 18.9077 15.1881 19.9999 15.1881C21.0921 15.1881 22.013 15.9001 22.013 16.7405H25.4234C25.4234 14.2727 23.4371 12.2225 20.8512 11.8478V9.76538H19.1486V11.8478C16.5627 12.2225 14.5764 14.2727 14.5764 16.7405C14.5764 18.1912 15.2671 19.567 16.4985 20.5252C16.6377 20.6322 17.9172 21.553 19.8553 21.7136C20.7816 21.7885 21.424 22.1686 21.4347 22.1686C21.6543 22.3452 22.013 22.7093 22.013 23.2499C22.013 24.0904 21.0921 24.797 19.9999 24.797C18.9077 24.797 17.9868 24.0904 17.9868 23.2499H14.5764C14.5764 25.7177 16.5627 27.7626 19.1486 28.1373V30.2197H20.8512V28.1373C23.4371 27.7626 25.4234 25.7177 25.4234 23.2499C25.4234 21.7939 24.7381 20.4181 23.4585 19.4224Z" />
    </svg>
  );
};
